import Button from '@components/Button'
import ModalBIDV from '@pages/ConfirmPayment/components/Modal/ModalBIDV'
import * as Sentry from '@sentry/browser'
import { NetWorkService, validResponse } from '@utils/API'
import { CODE_SUCCESS, SchoolCodeEnum } from '@utils/constan'
import { detectEnvUat, detectWebview } from '@utils/general'
import { message } from 'antd'
import type { TableRowSelection } from 'antd/es/table/interface'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { RootState } from 'store'
import { setDevice, setEduBillDetail, setLoading1, setStudenInfor, Type_Initstate } from 'store/appSlice'
import { BillType, ItemBillResponse, ITransactionHistory } from '../../types/general'
import IconBack from './Components/IconBack'
import SectionHeaderTable from './Components/SectionHeaderTable'
import TableCustom from './Components/Table'

export interface DataType {
  key: React.ReactNode
  name: string
  noidung: string
  phaiThu: string
  daThu: string
  mienGiam: string
  conNo: string
  status: string
  ma_sv: string
  expaned: string
  trang_thai: string
  tongThu: string
  hocKy: string
  loaiThu: string
  height?: number | string
  children?: DataType[]
  date_line: string
  kenh_thu: string
  ngay_tao: string
  ngay_thu: string
  hoc_ky_chu: string
  chi_tiet: string
  createdAt?: string
  so_phieu_bao_group?: string
  _id?: string
}

const Payment = () => {
  const { Get } = NetWorkService
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const appState = useSelector<RootState, Type_Initstate>((state) => state.app)

  const [searchedColumn, setSearchedColumn] = useState<
    | {
        trang_thai: {
          filter: number
          filterType: string
          type: string
        }
      }
    | undefined
  >({
    trang_thai: { filter: 0, filterType: 'text', type: 'eq' },
  })
  const [bill, setBill] = useState<BillType[]>([])
  const [cloneBill, setCloneBill] = useState<BillType[]>([])
  const [isPaid, setIsPaid] = useState(false)
  const [currentValue, setCurrentValue] = useState<string[]>([])
  const [checkBillType, setCheckBillType] = useState(false)
  const [billCheck, setBillCheck] = useState<BillType[]>([])
  const [paymentHistorys, setPaymentHistorys] = useState<ITransactionHistory[]>([])
  const [toastMsg, setToastMsg] = useState(false)
  const [checkSoPhieuBao, setCheckSoPhieuBao] = useState(false)
  const [status, setStatus] = useState(4)
  const [reRender, setReRender] = useState(0)
  const [visible, setVisible] = useState(false)
  const [imgBidv, setimgBidv] = useState()

  const [listSelect, setListSelect] = useState<string[]>([])
  const MIN_VALUE = 10000

  const index = useMemo(() => window.location.href.split('/').length, [])
  const secretCode = useMemo(() => window.location.href.split('/')[index - 1], [])

  const handleBack = () => {
    navigate(`${detectEnvUat() ? '/uat' : ''}/search/${params.orgCode}/school/${params.schoolCode}`)
  }

  const handleNavigate = () => {
    const filteredData = bill.filter((item) => !item.so_phieu_bao)
    setCheckSoPhieuBao(!filteredData.length ? false : true)

    if (billCheck.reduce((a, b) => a + b.phai_thu, 0) < MIN_VALUE) {
      setToastMsg(true)
      return false
    }

    !filteredData.length &&
      currentValue.length &&
      !checkBillType &&
      navigate(
        `${detectEnvUat() ? '/uat' : ''}/confirm-payment/organization/${params.orgCode}/school/${
          params.schoolCode
        }/student/${secretCode}`,
        {
          state: {
            currentValue: billCheck,
          },
        },
      )
  }

  const handleGetBillList = async (params: Readonly<Params<string>>) => {
    try {
      dispatch(setLoading1(true))
      let query = '?limit=99999&offset=0'
      if (searchedColumn && Object.keys(searchedColumn).length > 0) {
        for (const key in searchedColumn) {
          const type = searchedColumn[key].type
          const filter = searchedColumn[key].filter
          const filterType = searchedColumn[key].filterType

          switch (filterType) {
            case 'text' || 'number':
              if (key === 'trang_thai') {
                query += `&${key}[${type}]=${filter}`
              }
              if (key === 'trang_thai' && filter === 1) {
                query += '&ngay_thu[sort]=desc'
              }
              break
            default:
              break
          }
        }
      }

      let secretCode = window.location.href.split('/')[index - 1]

      const isWebview = detectWebview().Webview

      dispatch(setDevice(isWebview === 'Yes' ? 'app' : 'web'))
      if (secretCode.split('?').length > 1) {
        secretCode = secretCode.split('?')[secretCode.split('?').length - 2]
      }

      const url = `/api/organization/${params.orgCode}/school/${params.schoolCode}/secretCode/${secretCode}/bill`
      const res: any = await Get<ItemBillResponse>({
        url: url + query,
      })

      if (searchedColumn && searchedColumn?.trang_thai?.filter === 2) {
        handleGetPaymentHistory()
      }

      if (res && validResponse(res) && res.code === CODE_SUCCESS && res.data.data?.success !== false) {
        setBill(res.data.data.data.records.sort((a, b) => a?.stt - b?.stt))
      } else {
        setBill([])
        const msgErr = res && validResponse(res) ? res.data.data.message : 'Có lỗi xảy ra'
        message.error(msgErr)
        Sentry.captureMessage(msgErr)
        // navigate('/error')
      }
    } catch (error) {
      message.error('Có lỗi xảy ra')
      console.log(error)
      Sentry.captureException(error)
      setBill([])
      // navigate('/error')
    } finally {
      dispatch(setLoading1(false))
    }
  }

  const handleSetData = (paymentHistorysArgs: ITransactionHistory[], bill: BillType[]) => {
    try {
      let trang_thai = undefined
      if (searchedColumn && Object.keys(searchedColumn).length > 0) {
        for (const key in searchedColumn) {
          const filter = searchedColumn[key].filter

          if (key === 'trang_thai') {
            trang_thai = { trang_thai: filter }
          }
        }
      }

      let newArr = bill.reduce((a, b) => {
        const pendingPayment = paymentHistorysArgs.find((e) => e.so_phieu_bao === b.so_phieu_bao && e.status === 2)

        b = pendingPayment
          ? {
              ...b,
              status: pendingPayment.status,
              trang_thai: pendingPayment.status,
              so_phieu_bao_group: pendingPayment.bills.reduce((prev, next) => (prev += ` ${next.so_phieu_bao}`), ''),
              qrCode: pendingPayment.qrCode,
            }
          : { ...b }
        a.push(b)
        return a
      }, [])

      if (status !== 1) {
        newArr = newArr.sort((a, b) => a?.stt - b?.stt)
      }

      setBill(!trang_thai ? newArr : newArr.filter((e) => e.trang_thai === trang_thai.trang_thai))
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
    }
  }

  const handleGetPaymentHistory = async () => {
    try {
      if (appState.studenInfor) {
        const res: any = await Get<ItemBillResponse>({
          url: `/api/organization/${params.orgCode}/school/${params.schoolCode}/payment-history/${appState.studenInfor?.MaSV}?status[eq]=2`,
        })

        if (res && validResponse(res) && res.code === CODE_SUCCESS && res.data.data?.success !== false) {
          setPaymentHistorys(res.data.data)
        } else {
          const msgErr = res && validResponse(res) ? res.data.data.message : 'Có lỗi xảy ra'
          message.error(msgErr)
          Sentry.captureMessage(msgErr)
          navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
        }
      }
    } catch (error) {
      message.error('Có lỗi xảy ra')
      Sentry.captureException(error)
      navigate(`${detectEnvUat() ? '/uat' : ''}/error`)
    } finally {
      // dispatch(setLoading1(false))
    }
  }

  useEffect(() => {
    setIsPaid(bill.some((e) => e.trang_thai === 0 || e.trang_thai === 2))
  }, [bill])

  useEffect(() => {
    reRender >= 1 && searchedColumn && handleGetBillList(params)
    setReRender(reRender + 1)
  }, [searchedColumn])

  useEffect(() => {
    handleSetData(paymentHistorys, cloneBill)
  }, [paymentHistorys])

  // kiểm tra việc chọn khác loại phiếu thu
  useEffect(() => {
    if (currentValue.length) {
      const filteredData2 = bill.filter((item) => currentValue.includes(item.so_phieu_bao))
      const typeFeeCode = filteredData2[0]?.ma_loai_thu?.typeFeeCode
      setBillCheck(filteredData2)
      setCheckBillType(!filteredData2.every((e) => e?.ma_loai_thu?.typeFeeCode === typeFeeCode))
    }
    setToastMsg(false)
  }, [currentValue])

  useEffect(() => {
    if (appState?.listEdubillDetail.length !== 0) {
      const edubillDetail = appState?.listEdubillDetail.find((e) => e.orgCode === params.orgCode)
      dispatch(setEduBillDetail(edubillDetail))
    }
  }, [appState?.listEdubillDetail, params.orgCode])

  // lấy tất cả phiếu thu phục vụ cho filter
  const handleGetAllBill = async () => {
    try {
      dispatch(setLoading1(true))
      const url = `/api/organization/${params.orgCode}/school/${params.schoolCode}/secretCode/${secretCode}/bill?limit=99999&offset=0`

      const res: any = await Get<ItemBillResponse>({
        url: url,
      })

      if (res && validResponse(res) && res.code === CODE_SUCCESS && res.data.data?.success !== false) {
        const { records } = res.data.data.data
        setBill(records.filter((e) => e.trang_thai === 0).sort((a, b) => a?.stt - b?.stt))
        !cloneBill.length && setCloneBill(records.sort((a, b) => a?.stt - b?.stt))
        !appState?.studenInfor && dispatch(setStudenInfor(res.data.data.student))

        if (records?.every((e) => e?.is_aq_mobile)) {
          navigate(
            `${detectEnvUat() ? '/uat' : ''}/confirm-payment/organization/${params.orgCode}/school/${
              params.schoolCode
            }/student/${secretCode}`,
            {
              state: {
                currentValue: records,
              },
            },
          )
        }
      } else {
        const msgErr = res && validResponse(res) ? res.data.data.message : 'Có lỗi xảy ra'
        message.error(msgErr)
      }
    } catch (error) {
      message.error('Có lỗi xảy ra')
      console.log(error)
      Sentry.captureException(error)
    } finally {
      dispatch(setLoading1(false))
    }
  }

  useEffect(() => {
    handleGetAllBill()
  }, [])

  return (
    <div className="relative overflow-hidden">
      <div>
        {appState.device === 'web' && (
          <>
            <span className="text-[#595959] underline cursor-pointer flex mt-2 mb-3 w-fit" onClick={handleBack}>
              <IconBack /> Tra cứu học phí
            </span>
            {appState.studenInfor && (
              <div className="md:hidden my-2">
                <p>
                  Tên học viên:{' '}
                  <span className="text-[#151515] text-[16px] font-[500] mb-1">{appState.studenInfor?.HoTen}</span>
                </p>
                <p>
                  Mã học viên:{' '}
                  <span className="text-[#151515] text-[16px] font-[500]">{appState.studenInfor?.MaSV}</span>
                </p>
              </div>
            )}
          </>
        )}
        <SectionHeaderTable
          bill={cloneBill}
          setSearchedColumn={setSearchedColumn}
          searchedColumn={searchedColumn}
          setStatus={setStatus}
        />
        <TableCustom
          bills={bill}
          status={status}
          currentValue={currentValue}
          paymentHistorys={paymentHistorys}
          listSelect={listSelect}
          setListSelect={setListSelect}
          setCurrentValue={setCurrentValue}
          setimgBidv={setimgBidv}
        />
        {toastMsg && checkBillType && (
          <p className="text-[#FD0606BF] font-[13px] text-right mt-4">
            *Vui lòng chỉ thanh toán các giao dịch cùng loại thu
          </p>
        )}
        {toastMsg && !billCheck.every((e) => e.qrCode) && (
          <p className="text-[#FD0606BF] font-[13px] text-right mt-4">
            *Tiếp tục thanh toán chỉ khả dụng cho phương thức thanh toán BIDV
          </p>
        )}
        {toastMsg && !listSelect.length && !currentValue.length && (
          <p className="text-[#FD0606BF] font-[13px] text-right mt-4">*Vui lòng chọn phiếu thu để thanh toán</p>
        )}
        {toastMsg && !!currentValue.length && billCheck.reduce((a, b) => a + b.phai_thu, 0) < MIN_VALUE && (
          <p className="text-[#FD0606BF] font-[13px] text-right mt-4">
            *Số tiền giao dịch nhỏ hơn 10.000 đ. Vui lòng liên hệ đóng trực tiếp tại nhà trường
          </p>
        )}
        {checkSoPhieuBao && currentValue.length && (
          <p className="text-[#FD0606BF] font-[13px] text-right mt-4">
            Phiếu thu bị lỗi. Vui lòng liên hệ phòng Quản lý đào tạo để khiếu nại
          </p>
        )}
        <div className={`mt-6 flex justify-end ${appState.device !== 'web' && 'my-6'}`}>
          {isPaid && (
            <div className="ml-4">
              <Button
                width="w-[200px]"
                text={searchedColumn?.trang_thai?.filter !== 2 ? 'Thanh toán' : 'Tiếp tục thanh toán'}
                onClick={() => {
                  setToastMsg(true)
                  if (searchedColumn?.trang_thai?.filter !== 2) {
                    handleNavigate()
                  } else {
                    billCheck.every((e) => e.qrCode) && billCheck.length && setVisible(true)
                  }
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ModalBIDV setIsModalOpen={setVisible} isModalOpen={visible} img={imgBidv} allowCancel />
    </div>
  )
}

export default Payment
